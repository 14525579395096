import { useSortable } from "@dnd-kit/sortable";
import AppCard from "../index";

function SortableAppCard({
  key,
  app,
  contentId,
  isDashboard,
  userId,
  isFavorite,
  onFavoriteChange,
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: contentId || app?.appId || "defid" });

  return (
    <>
      {contentId || app?.appId ? (
        <div
          ref={setNodeRef}
          style={{
            transform: transform
              ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
              : undefined,
            transition: transition || undefined,
            opacity: isDragging ? 0.5 : 1,
            cursor: "grab",
            ...attributes.style,
          }}
          {...listeners}
          {...attributes}
        >
          <AppCard
            contentId={contentId || undefined} // Set contentId if available
            app={contentId ? undefined : app} // Set app only if contentId is not present
            isFavorite={isFavorite}
            onFavoriteChange={(key, isFav) => onFavoriteChange(key, isFav)}
            userId={userId}
            isDashboard={isDashboard}
          />
        </div>
      ) : null}
    </>
  );
}

export default SortableAppCard;
