import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFavorites,
  removeFavoriteContents,
  updateFavoriteContentsOrder,
} from "./../../../redux/actions/graph/favorites";
import styles from "../styles";
import Loader from "../../../components/Loader";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { arrayMove } from "@dnd-kit/sortable";
import SortableAppCard from "../../../components/AppCard/SortableAppCard";

function FavoriteContents(props) {
  const dispatch = useDispatch();
  const { classes } = styles();
  const isMobile = useMediaQuery("(max-width:768px)");
  const loading = useSelector((state) => state.getFavoritesContents.loading);
  const { userId, favoritesContents, isDashboard, fetched } = props;

  const handleFavoriteContentChangeInFavorites = (contentId, isFav) => {
    if (isFav) {
      dispatch(removeFavoriteContents({ userId, contentId })).then((res) => {
        if (!res.payload.error) {
          dispatch(fetchFavorites(userId));
        }
      });
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const oldIndex = favoritesContents?.findIndex(
        (contentId) => contentId === active.id
      );
      const newIndex = favoritesContents?.findIndex(
        (contentId) => contentId === over.id
      );
      if (oldIndex !== -1 && newIndex !== -1) {
        const newItems = arrayMove(favoritesContents, oldIndex, newIndex);
        dispatch(
          updateFavoriteContentsOrder({
            userId,
            contentId: active.id,
            newItems,
          })
        ).then((res) => {
          if (!res.payload.error) dispatch(fetchFavorites(userId));
        });
      } else {
        console.error("Could not find items for drag end event");
      }
    }
  };

  const dashboardFilterFavoritesContents = favoritesContents
    ? favoritesContents.slice(0, 8)
    : [];
  const MobileDashboardFilterFavoritesContents = favoritesContents
    ? favoritesContents.slice(0, 2)
    : [];

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  return (
    <>
      {loading ? (
        <Loader isDashboard={isDashboard} />
      ) : isDashboard ? (
        <Grid
          container
          className={
            dashboardFilterFavoritesContents &&
            dashboardFilterFavoritesContents.length === 0
              ? isMobile
                ? classes.favMobileDashboardContainerNoApps
                : classes.favMobileDashboardContainer
              : isMobile
              ? classes.favMobileDashboardContainer
              : classes.favDashboardContainer
          }
        >
          <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
            <Grid
              item
              md={12}
              className={
                isMobile
                  ? classes.favMobileAppCardContainer
                  : classes.favAppCardContainer
              }
            >
              {dashboardFilterFavoritesContents &&
              dashboardFilterFavoritesContents.length === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <h4>No favorite Contents currently in your list.</h4>
                </Box>
              ) : (
                <>
                  {isMobile ? (
                    <SortableContext
                      items={
                        MobileDashboardFilterFavoritesContents &&
                        MobileDashboardFilterFavoritesContents.map(
                          (contentId) => contentId
                        )
                      }
                      strategy={rectSortingStrategy}
                    >
                      {MobileDashboardFilterFavoritesContents &&
                        MobileDashboardFilterFavoritesContents.map(
                          (contentId, index) => {
                            if (contentId !== null) {
                              return (
                                <SortableAppCard
                                  key={contentId}
                                  contentId={contentId}
                                  userId={userId}
                                  isFavorite={true}
                                  isDashboard={isDashboard}
                                  onFavoriteChange={
                                    handleFavoriteContentChangeInFavorites
                                  }
                                />
                              );
                            }
                          }
                        )}
                    </SortableContext>
                  ) : (
                    <SortableContext
                      items={
                        dashboardFilterFavoritesContents &&
                        dashboardFilterFavoritesContents.map(
                          (contentId) => contentId
                        )
                      }
                      strategy={rectSortingStrategy}
                    >
                      {" "}
                      {dashboardFilterFavoritesContents &&
                        dashboardFilterFavoritesContents.map(
                          (contentId, index) => {
                            if (contentId !== null) {
                              return (
                                <SortableAppCard
                                  key={contentId}
                                  contentId={contentId}
                                  userId={userId}
                                  isFavorite={true}
                                  isDashboard={isDashboard}
                                  onFavoriteChange={
                                    handleFavoriteContentChangeInFavorites
                                  }
                                />
                              );
                            }
                          }
                        )}
                    </SortableContext>
                  )}
                </>
              )}
            </Grid>
          </DndContext>
        </Grid>
      ) : (
        <Grid container className={classes.favContainer}>
          <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
            <Grid
              item
              md={12}
              style={{
                display: "flex",
                flexWrap: "wrap",
                paddingTop: "20px",
              }}
            >
              {favoritesContents && favoritesContents.length === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    paddingLeft:"40px"
                  }}
                >
                  <h4>No favorite Contents currently in your list.</h4>
                </Box>
              ) : (
                <SortableContext
                  items={
                    favoritesContents &&
                    favoritesContents.map((contentId) => contentId)
                  }
                  strategy={rectSortingStrategy}
                >
                  {favoritesContents &&
                    favoritesContents.map((contentId, index) => {
                      if (contentId !== null) {
                        return (
                          <SortableAppCard
                            key={contentId}
                            contentId={contentId}
                            userId={userId}
                            isFavorite={true}
                            isDashboard={isDashboard}
                            onFavoriteChange={
                              handleFavoriteContentChangeInFavorites
                            }
                          />
                        );
                      }
                    })}
                </SortableContext>
              )}
            </Grid>
          </DndContext>
        </Grid>
      )}
    </>
  );
}

export default FavoriteContents;
