import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme) => {
    return {
        cardRoot: {
            width: '220px',
            [theme.breakpoints.down("sm")]: {
                width: '172px',
            },
            margin: theme.spacing(1),
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
            }
        },
        favoriteCardRoot: {
            width: '350px',
            height: '60px',
            borderRadius: '1px',
            margin: '15px',
            backgroundColor: theme.palette.primary.light,
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
            },
            touchAction: "none"
        },
        favoriteDashboardCardRoot: {
            width: '350px',
            height: '60px',
            borderRadius: '1px',
            backgroundColor: theme.palette.primary.light,
            [theme.breakpoints.down("sm")]: {
                width: '284px',
            },
            margin: '15px',
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
            },
            touchAction: "none"
        },
        cardContentPadding: {
            padding: '6px 12px 6px 12px !important'
        },
        imgBox: {
            display: 'flex',
            justifyContent: 'space-around'
        },
        imgStyle: {
            maxWidth: '70%',
        },
        cardTxt: {
            color: theme.palette.info.main,
            fontFamily: "Montserrat-Regular",
            fontStyle: 'normal',
            lineHeight: '18px !important',
            fontSize: '13px !important',
        },
        favIconColor: {
            color: '#417BB0'
        },
        customTooltip: {
            backgroundColor: `${theme.palette.info.main} !important`,
            fontFamily: "Montserrat-Regular !important",
            color: `${theme.palette.common.white} !important` ,
            fontWeight: "600 !important",
            fontSize: "14px !important",
            pointerEvents: "auto",
            borderRadius:"5px !important",
            boxShadow: `${theme.shadows[1]} !important`,
            maxWidth:`${theme.spacing(40)} !important`,
          },
    }
}
);

export default styles;