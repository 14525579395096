import {createSlice} from '@reduxjs/toolkit'
import { getPreviewPageData } from '../../actions/previewPage/previewPage';

const initialState = {
    loading: false,
    error: false,
    preview:[],
    message: null,
  };

const previewPageSlice = createSlice({
    name:'preview',
    initialState,
    reducers: { },
    extraReducers: (builder) => {
      builder.addCase(getPreviewPageData.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(getPreviewPageData.fulfilled, (state, action) => {
        state.loading = false;
        state.preview = action.payload;
        state.error = "";
      });
      builder.addCase(getPreviewPageData.rejected, (state, action) => {
        state.loading = false;
        state.preview = [];
        state.error = action.error.message;
      });
    }
})

export default previewPageSlice.reducer