import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useMsal } from "@azure/msal-react";
import styles from "./styles";
import { useNavigate } from "react-router-dom";

export default function ResponsiveDialog({
  open,
  setOpen,
  title,
  description,
  activityButtonMessage,
  handleContinueSession
}) {
  const theme = useTheme();
  const { classes } = styles();
  const { instance } = useMsal();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate=useNavigate()
  
  const handleClose = () => {
    if (activityButtonMessage === "Login") {
      sessionStorage.setItem("sessionDialog", "false");
      sessionStorage.setItem("refreshDialog", "false");
    }
    if (activityButtonMessage === "Logout" && handleContinueSession){
      handleContinueSession()
    }
    setOpen(false);
  };
  const handleActivity = () => {
    setOpen(false);
    if (activityButtonMessage === "Login"|| activityButtonMessage === "Logout" ) {
      sessionStorage.setItem("closedAlerts", JSON.stringify([]));
      localStorage.removeItem("oAuthToken");
      sessionStorage.removeItem("sessionDialog");
      sessionStorage.removeItem("refreshDialog");
      navigate("/")
      instance.logout(); // Sign out the user
    }
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          className={classes.dialogBoxTitle}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogBoxDescription}>
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            className={classes.dialogBoxCancelButton}
          >
            {activityButtonMessage === "Logout" ? "OK" : "Cancel"}
          </Button>
          <Button
            onClick={handleActivity}
            className={classes.dialogBoxActivityButton}
          >
            {activityButtonMessage}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
