import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    trainingListItem: null,
};

const trainingListSlice = createSlice({
    name: 'trainingList',
    initialState,
    reducers: {
        setTrainingList: (state, action) => {
            state.trainingListItem = action.payload.trainingListItem;
        },
        clearTrainingList: (state) => {
            state.trainingListItem = null;
        },
    },
});

export const { setTrainingList } = trainingListSlice.actions;
export default trainingListSlice.reducer;
