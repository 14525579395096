import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme) => {
  return {
    sliderContainer: {
      "& .slick-prev:before": {
        content: "none",
      },
      "& .slick-next:before": {
        content: 'none',
      }
    },
    content: {
      height: "100%",
      // backgroundColor: "rgba(0, 0, 0, 0.3)",
      // display: "flex",
      // justifyContent: "flex-start",
      // alignItems: "baseline",
      marginTop: theme.spacing(0),
      marginLeft: theme.spacing(0),
      [theme.breakpoints.down("md")]: {
        paddingTop: theme.spacing(2),
        //paddingLeft: theme.spacing(8),
        paddingBottom: theme.spacing(8),
        //paddingRight: theme.spacing(8),
      },
      "& h1": {
        [theme.breakpoints.down("md")]: {
          paddingTop: theme.spacing(0),
          marginTop: theme.spacing(0),
          fontSize: "32px",
          lineHeight: "40px",
        },
      },
      "& a": {
        TextDecoration: "none !important",
      },
    },
    favDashboardcontent: {
      position:"sticky",
      marginTop: "0px",
      display: 'block',
      paddingTop: '16px',
    },
    favMobileDashboardcontent: {
      position:"sticky",
      marginTop: "0px",
      display: 'block',
      paddingTop: '16px'
    },
    favContentItem1: {
      display: "flex",
      justifyContent: "space-around",
      paddingTop: '0px !important',
      [theme.breakpoints.down("md")]: {paddingLeft: '48px !important',},
      maxWidth:"10% !important",
      [theme.breakpoints.down("sm")]: {
        //bottom: '0px !important',
        //paddingLeft: '0px !important',
        //paddingTop: '0px !important',
        //padding: '0px!important',
        //right: '70px !important'
      },
    },
    favContentItem2: {
      maxWidth: '100% !important',
      paddingTop: '0px !important'
    },
    heading: {
      textAlign: "left",
      //margin: "54px 0 0 20px",
      letterSpacing: "2.4px",
    },
    description: {
      fontFamily: "Montserrat-Regular",
      fontSize: "16px",
      fontWeight: "600",
      lineHeight: "32px",
      textAlign: "left",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down("md")]: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 6,
        WebkitBoxOrient: "vertical",
        lineHeight: "28px",
      },
    },
    btn: {
      display: "flex !important",
      justifyContent: "center !important",
      alignItems: "center !important",
      borderRadius: "31px !important",
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      textAlign: "center !important",
      fontFamily: "Montserrat-Regular !important",
      fontSize: "16px !important",
      fontWeight: "600 !important",
      lineHeight: "16px !important",
      letterSpacing: "0.1px !important",
      textDecoration: "none",
      //marginTop: "24px !important",
      //marginLeft: "75px !important",
    },
    btnArrow: {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      display: "flex",
      padding: "6px 12px 6px 8px",
      justifyContent: "center",
      alignItems: "center",
      gap: "8px",
      textTransform: "capitalize",
      borderRadius: "31px !important",
      "& span": {
        TextDecoration: "none !important",
      },
    },
    dots: {
      position: "absolute",
      bottom: "16px",
      left: "50%",
      transform: "translateX(-50%)",
      zIndex: "1",
    },
    dotsList: {
      display: "inline-flex",
      margin: "0px",
      padding: "0px",
    },
  }
}
);

export default styles;
