import React, { useEffect, useState } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Grid, useMediaQuery } from '@mui/material';
import styles from "./styles";
import Facebook from "./../../assets/images/fb.svg";
import Instagram from "./../../assets/images/insta.svg";
import LinkedIn from "./../../assets/images/linkedin.svg";
import twitter from "./../../assets/images/twitter.svg";
import YouTube from "./../../assets/images/youtube.svg";
import { Link } from "react-router-dom";

export default function Footer({id}) {
	const { classes } = styles();
	const theme = useTheme();
	const [year, setYear] = useState(new Date().getFullYear());
	const isMobile = useMediaQuery("(max-width:768px)");
	useEffect(() => {
		// Update the year when the component mounts
		setYear(new Date().getFullYear());
	}, []);
	return (
		<Box id={id} className={classes.root} sx={{ flexGrow: 1, bottom:0, width:"97%" }}>
			<Grid 
				container
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={2}
				style={{marginTop:'-8px'}}
			>
				<Grid item xs={12} sm={6} md={6} style={{marginTop:'-8px'}}>
				<div className={classes.copyWrite}>
				{`© ${year} Steward Partners. All Rights Reserved.`}
			</div>
				</Grid>
				<Grid item xs={12} sm={6} md={6} style={{paddingTop:isMobile? '10px':'8px' , }}>
				<div className={classes.social}>
				<div>
					<Link to='https://www.facebook.com/StewardPartnersGlobalAdvisoryLLC/' target='_blank'><img src={Facebook} /></Link>
					<Link to='https://twitter.com/StewardPartners' target='_blank'><img src={twitter} className={classes.icons} /></Link>
					<Link to='https://www.linkedin.com/company/steward-partners-global-advisory/' target='_blank'><img src={LinkedIn} className={classes.icons} /></Link>
					<Link to='https://www.youtube.com/@stewardpartners' target='_blank'><img src={YouTube} className={classes.icons} /></Link>
					<Link to='https://www.instagram.com/stewardpartners/' target='_blank'><img src={Instagram} className={classes.icons} /></Link>
				</div>
			</div>
				</Grid>
			</Grid>
			
			

		</Box>
	);
}