import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFavorites,
  removeFavorite,
  updateFavoriteOrder,
} from "./../../redux/actions/graph/favorites";
import styles from "./styles";
import Loader from "../../components/Loader";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { arrayMove } from "@dnd-kit/sortable";
import SortableAppCard from "../../components/AppCard/SortableAppCard";

function FavoriteApps(props) {
  const dispatch = useDispatch();
  const { classes } = styles();
  const apps = useSelector((state) => state.apps.apps);
  const loading = useSelector((state) => state.getFavorites.loading);
  const { userId,favoritesApps,isDashboard,fetched } = props;

  const uniqueApps = new Map();
  apps.forEach((entry) => {
    if (entry !== undefined && !uniqueApps.has(entry.appId)) {
      uniqueApps.set(entry.appId, entry);
    }
  });

  const filteredAppsData = Array.from(uniqueApps.values());

  function FilterFavoritesApps(appsData, favoritesData) {
    const filterFavoritesApps = [];
    favoritesData?.getFavorites?.favorites?.forEach((appId) => {
        const matchingApp = appsData.find((app) => app.appId === appId);
        if (matchingApp) {
          const mergedData = { ...matchingApp, ...favoritesData.getFavorites };
          filterFavoritesApps.push(mergedData);
        }
      });
    return filterFavoritesApps;
  }

  const isMobile = useMediaQuery("(max-width:768px)");
  const filterFavoritesApps = FilterFavoritesApps(
    filteredAppsData,
    favoritesApps
  );
  const dashboardFilterFavoritesApps = filterFavoritesApps.slice(0, 8);
  const MobileDashboardFilterFavoritesApps = filterFavoritesApps.slice(0, 2);

  const handleFavoriteChangeInFavorites = (appId, isFav) => {
    if (isFav) {
      dispatch(removeFavorite({ userId, appId })).then((res) => {
        if (!res.payload.error) {
          dispatch(fetchFavorites(userId));
        }
      });
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active && over && active.id !== over.id) {
      const oldIndex = filterFavoritesApps.findIndex(
        (item) => item.appId === active.id
      );
      const newIndex = filterFavoritesApps.findIndex(
        (item) => item.appId === over.id
      );

      if (oldIndex !== -1 && newIndex !== -1) {
        const newItemsArray = arrayMove(filterFavoritesApps, oldIndex, newIndex);
        const newItems = newItemsArray.map((newItem=>newItem.appId))
        dispatch(
          updateFavoriteOrder({ userId, appId: active.id, newItems })
        ).then((res) => {
          if (!res.payload.error) dispatch(fetchFavorites(userId));
        });
      } else {
        console.error("Could not find items for drag end event");
      }
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );
  return (
    <>
      {loading ? (
        <Loader isDashboard={isDashboard} />
      ) : isDashboard ? (
        <Grid
          container
          className={
            dashboardFilterFavoritesApps?.length === 0
              ? isMobile
                ? classes.favMobileDashboardContainerNoApps
                : classes.favMobileDashboardContainer
              : isMobile
              ? classes.favMobileDashboardContainer
              : classes.favDashboardContainer
          }
        >
          <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
            <Grid
              item
              md={12}
              className={
                isMobile
                  ? classes.favMobileAppCardContainer
                  : classes.favAppCardContainer
              }
            >
              {dashboardFilterFavoritesApps?.length === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <h4>No favorite Apps currently in your list.</h4>
                </Box>
              ) : (
                <>
                  {isMobile ? (
                    <SortableContext
                      items={
                        MobileDashboardFilterFavoritesApps &&
                        MobileDashboardFilterFavoritesApps.map(
                          (app) => app.appId
                        )
                      }
                      strategy={rectSortingStrategy}
                    >
                      {MobileDashboardFilterFavoritesApps &&
                        MobileDashboardFilterFavoritesApps.map((app, index) => {
                          if (app.appId !== null) {
                            return (
                              <SortableAppCard
                                key={app.appId}
                                app={app}
                                userId={userId}
                                isFavorite={true}
                                isDashboard={isDashboard}
                                onFavoriteChange={
                                  handleFavoriteChangeInFavorites
                                }
                              />
                            );
                          }
                        })}
                    </SortableContext>
                  ) : (
                    <SortableContext
                      items={
                        dashboardFilterFavoritesApps &&
                        dashboardFilterFavoritesApps.map((app) => app.appId)
                      }
                      strategy={rectSortingStrategy}
                    >
                      {" "}
                      {dashboardFilterFavoritesApps &&
                        dashboardFilterFavoritesApps.map((app, index) => {
                          if (app.appId !== null) {
                            return (
                              <SortableAppCard
                                key={app.appId}
                                app={app}
                                userId={userId}
                                isFavorite={true}
                                isDashboard={isDashboard}
                                onFavoriteChange={
                                  handleFavoriteChangeInFavorites
                                }
                              />
                            );
                          }
                        })}
                    </SortableContext>
                  )}
                </>
              )}
            </Grid>
          </DndContext>
        </Grid>
      ) : (
        <Grid container className={classes.favContainer}>
          <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
            <Grid
              item
              md={12}
              style={{
                display: "flex",
                flexWrap: "wrap",
                paddingTop: "20px",
              }}
            >
              {filterFavoritesApps?.length === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    paddingLeft:"40px"
                  }}
                >
                  <h4>No favorite Apps currently in your list.</h4>
                </Box>
              ) : (
                <SortableContext
                  items={
                    filterFavoritesApps &&
                    filterFavoritesApps.map((app) => app.appId)
                  }
                  strategy={rectSortingStrategy}
                >
                  {filterFavoritesApps &&
                    filterFavoritesApps.map((app, index) => {
                      if (app.appId !== null) {
                        return (
                          <SortableAppCard
                            key={app.appId}
                            app={app}
                            userId={userId}
                            isFavorite={true}
                            isDashboard={isDashboard}
                            onFavoriteChange={handleFavoriteChangeInFavorites}
                          />
                        );
                      }
                    })}
                </SortableContext>
              )}
            </Grid>
          </DndContext>
        </Grid>
      )}
    </>
  );
}

export default FavoriteApps;
