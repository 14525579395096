import React from 'react';
import { Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';
import styles from "./styles";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setTrainingList } from '../../redux/reducers/TrainingList/trainingListSlice';

export default function TrainingCard({ trainingData, setOpenDialog }) {
    const { classes } = styles();
    const dispatch = useDispatch();

    const handleTrainingList = (item) => {
        dispatch(
            setTrainingList({
                trainingListItem: item,
            })
        );
        setOpenDialog(false)
    };

    return (
        <Grid
            container
            spacing={2}
            justifyContent="flex-start"
            sx={{ padding: 2 }}
        >
            {trainingData && trainingData.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <Link style={{ textDecoration: 'none' }} to={'/TrainingList'} onClick={() => handleTrainingList(item)}>
                        <Card className={classes.trainualRootCard}>
                            <CardMedia
                                component="img"
                                image={item.subject_image}
                                alt={item.subject_title}
                                className={classes.trainualCardImg}
                            />
                            <CardContent>
                                <Typography className={classes.trainualCardTitle} gutterBottom variant="h5" component="div">
                                    {item.subject_title.replace(/&amp;/gi, '&')}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" className={classes.trainualCardDescription}>
                                    {item.subject_category}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
            ))}
        </Grid>
    );
};
