import * as React from "react";
import { useTheme } from '@mui/material/styles';
import styles from "./styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CircleIcon from "@mui/icons-material/Circle";
import { useState } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { postPolls } from "../../redux/actions/polls/pollsPost";

export default function Polls({ polls, setOpen, setShow, oAuthToken }) {
  const theme = useTheme();
  const { classes } = styles();
  const dispatch = useDispatch();
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const findKeyByValue = (obj, value) => {
    for (let key in obj) {
      if (obj[key] === value) {
        return key;
      }
    }
    return null;
  };

  const handleVoteSubmit = () => {
    if (oAuthToken && value) {
      setShow(false);
      setOpen(false)
      const pollOption = findKeyByValue(polls.answer, value);
      console.log("poll",pollOption,polls.answer,parseFloat(polls.poll_id),parseFloat(pollOption),oAuthToken)
      dispatch(
        postPolls({
          poll_id: parseFloat(polls.poll_id),
          poll_option: parseFloat(pollOption),
        oAuthToken})
      );
    }
  };

  return (
    <div
    className={classes.root}
    >
      <FormControl className={classes.form}>
        <FormLabel
          id="radio-buttons-group"
          className={classes.formHeading}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: polls.question,
            }}
          ></span>
        </FormLabel>
        <div
          className={classes.formSubHeading}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: polls.poll_expiry
                ? polls.poll_expiry.days + " days Left "
                : "",
            }}
          ></span>
          <CircleIcon className={classes.circle} />
          <span
            dangerouslySetInnerHTML={{
              __html: polls.total_votes + " votes received",
            }}
          ></span>
        </div>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          className={classes.radioBtn}
          value={value}
          onChange={handleChange}
        >
          {polls.answer &&
            Object.keys(polls.answer).map((key) => {
              return (
                <FormControlLabel
                  key={key}
                  value={polls.answer[key]}
                  control={
                    <Radio
                      sx={{
                        color: theme.palette.primary.light,
                        paddingRight: "16px",
                        "&.Mui-checked": { color: theme.palette.primary.main },
                        "& .MuiSvgIcon-root": {
                          fontSize: 24,
                        },
                      }}
                    />
                  }
                  label={
                    <Box
                      className={classes.radioLebel}
                    >
                      <span
                        dangerouslySetInnerHTML={{ __html: polls.answer[key] }}
                      ></span>
                    </Box>
                  }
                  style={{ marginBottom: "4px" }}
                />
              );
            })}
        </RadioGroup>
      </FormControl>
      <Button
        className={classes.btn}
        type="submit"
        onClick={() => {
          handleVoteSubmit();
        }}
      >
        <div
          className={classes.btnArrow}
        >
          <CheckCircleOutlineIcon />
          Vote
        </div>
      </Button>
    </div>
  );
}
