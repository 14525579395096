import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme) => {
  return {
    pollsCard: {
      width: "350px",
      paddingBottom: "16px",
      right: "32px",
      top: "134px",
    },
  }
}
);

export default styles;
