import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

export default function CustomizedSnackbars({ message, vertical, horizontal, open, setOpen, failure, attachment }) {
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:768px)');

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      autoHideDuration={attachment ? 3000 : 5000}
      onClose={handleClose}
      TransitionComponent="GrowTransition"
    >
      <Alert
        onClose={handleClose}
        severity={failure ? "error" : "success"}
        variant="filled"
        sx={{
          width: isMobile ? "90%" : "100%",
          backgroundColor: failure ? theme.palette.error.light : theme.palette.success.light,
          color: failure ? theme.palette.error.dark : theme.palette.success.dark,
          fontFamily: "Montserrat-Regular",
          fontSize: "12px",
          fontWeight: "400"
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
