import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    popular: {
      color: theme.palette.info.main,
      fontFamily: "Oswald-Regular",
      fontSize: '14px',
      fontWeight: '700',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    searchInput: {
      flexDirection: 'row-reverse',
      padding: theme.spacing(1),
    },
    chips: {
      borderRadius: theme.spacing(2),
      backgroundColor: theme.palette.primary.light,
      border: `2px solid ${theme.palette.primary.main}`,
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      padding: theme.spacing(1),
      //marginTop: theme.spacing(2),
    },
    icon: {
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(2),
      borderRadius: theme.spacing(6),
      width: '16px',
      height: '16px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.common.white,
    },
    txt: {
      fontFamily: "Montserrat-Regular",
      color: theme.palette.info.dark,
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '24px',
    },
    filterTxt: {
      color: theme.palette.primary.main,
      fontSize: '14px',
    },
    result: {
      '& .MuiToolbar-root': {
        paddingLeft: '0px',
        paddingRight: '0px',
      }
    },
    trainualContainer: {
      marginTop: '50px'
    },
    trainualMessage: {
      display: 'flex',
      justifyContent: 'space-around',
      paddingBottom: '20px',
      color: theme.palette.secondary.main,
      textAlign: "center",
      fontFamily: "Oswald-Regular",
      fontSize: "24px",
      fontWeight: "500",
      lineHeight: "36px",
      letterSpacing: "0.1px",
    }
  }
}
);


export default styles;