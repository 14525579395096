// reducers/graphSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  fetchFavorites,
  addFavorite,
  removeFavorite,
  updateFavoriteOrder,
} from "../../actions/graph/favorites";

const initialState = {
  getFavorites: [],
  favorites: [],
  loading: false,
  error: null,
  fetched: false,
};

const getFavoritesSlice = createSlice({
  name: "favorites",
  initialState,
  extraReducers: (builder) => {
    //Get Favorites Apps
    builder.addCase(fetchFavorites.pending, (state) => {
      state.loading = true;
      state.fetched = false;
    });
    builder.addCase(fetchFavorites.fulfilled, (state, action) => {
      state.loading = false;
      state.getFavorites = action.payload!==undefined?action.payload[0]:{};
      state.error = "";
      state.fetched = true;
    });
    builder.addCase(fetchFavorites.rejected, (state, action) => {
      state.loading = false;
      state.getFavorites = [];
      state.error = action.error.message;
      state.fetched = false;
    });
    //Add Favorites Apps
    builder.addCase(addFavorite.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.fetched = false;
    });
    builder.addCase(addFavorite.fulfilled, (state, action) => {
      state.loading = false;
      state.favorites.push(action.payload);
      state.error = "";
      state.fetched = true;
    });
    builder.addCase(addFavorite.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.fetched = false;
    });
    //Remove Favorites Apps
    builder.addCase(removeFavorite.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.fetched = false;
    });
    builder.addCase(removeFavorite.fulfilled, (state, action) => {
      state.loading = false;
      state.favorites = state.favorites.filter(
        (fav) => fav.appId !== action.payload.appId
      );
      state.error = "";
      state.fetched = false;
    });
    builder.addCase(removeFavorite.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.fetched = false;
    });
    //Update Favorites order
    builder.addCase(updateFavoriteOrder.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.fetched = false;
    });
    builder.addCase(updateFavoriteOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.favorites = action.payload;
      state.error = "";
      state.fetched = true;
    });
    builder.addCase(updateFavoriteOrder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.fetched = false;
    });
  },
});


export default getFavoritesSlice.reducer;
