import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getIdToken } from "./../../../helper/auth";

const hostname = window.location.hostname;

const port = window.location.port;

const portalApiBaseUrl = port ? 'http://'+hostname + ":" + port : 'https://'+hostname;

export const fetchFavorites = createAsyncThunk(
  "apps/fetchFavorites",
  async (userId) => {
    try {
      const IdToken = await getIdToken();
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${IdToken}`,
        },
      };
      const response = await axios.get(
        `${portalApiBaseUrl}/api/favorites/${userId}`,
        headers
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching favorites:", error);
    }
  }
);

export const addFavorite = createAsyncThunk(
  "addFavorite",
  async ({ userId, appId }, { rejectWithValue }) => {
    try {
      const IdToken = await getIdToken();
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${IdToken}`, //`Bearer ${IdToken}` if access token authorization
        },
      };
      const favoritesData = {
        action: "add",
        data: {
          userId: userId,
          favorites: [appId],
        },
      };
      const response = await axios.post(
        `${portalApiBaseUrl}/api/favorites`,
        favoritesData,
        headers
      );
      return response.data.favorites;
    } catch (error) {
      //throw error;
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const removeFavorite = createAsyncThunk(
  "removeFavoriteApps",
  async ({ userId, appId }, { rejectWithValue }) => {
    try {
      const IdToken = await getIdToken();
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${IdToken}`, //`Bearer ${IdToken}` if access token authorization
        },
      };
      const favoritesData = {
        action: "remove",
        data: {
          userId: userId,
          favorites: [appId],
        },
      };
      const response = await axios.post(
        `${portalApiBaseUrl}/api/favorites`,
        favoritesData,
        headers
      );
      return response.data.favorites;
    } catch (error) {
      //throw error;
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateFavoriteOrder = createAsyncThunk(
  "updateFavoriteOrder",
  async ({ userId, appId, newItems }, { rejectWithValue }) => {
    try {
      const IdToken = await getIdToken();
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${IdToken}`, //`Bearer ${IdToken}` if access token authorization
        },
      };
      const favoritesData = {
        action: "reorder",
        data: {
          userId: userId,
          favorites: [appId],
          newItems: newItems
        },
      };
      const response = await axios.post(
        `${portalApiBaseUrl}/api/favorites`,
        favoritesData,
        headers
      );
      return response.data.favorites;
    } catch (error) {
      //throw error;
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//Content Favorites

export const addFavoriteContents = createAsyncThunk(
  "addFavoriteContents",
  async ({ userId, contentId }, { rejectWithValue }) => {
    try {
      const IdToken = await getIdToken();
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${IdToken}`, //`Bearer ${IdToken}` if access token authorization
        },
      };
      const favoritesData = {
        action: "add",
        data: {
          userId: userId,
          favoritesContents: typeof contentId==='string'? [contentId]:[],
        },
      };
      const response = await axios.post(
        `${portalApiBaseUrl}/api/favoritesContents`,
        favoritesData,
        headers
      );
      return response.data.favoritesContents;
    } catch (error) {
      //throw error;
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const removeFavoriteContents = createAsyncThunk(
  "removeFavoriteContents",
  async ({ userId, contentId }, { rejectWithValue }) => {
    try {
      const IdToken = await getIdToken();
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${IdToken}`, //`Bearer ${IdToken}` if access token authorization
        },
      };
      const favoritesData = {
        action: "remove",
        data: {
          userId: userId,
          favoritesContents: typeof contentId==='string'? [contentId]:[],
        },
      };
      const response = await axios.post(
        `${portalApiBaseUrl}/api/favoritesContents`,
        favoritesData,
        headers
      );
      return response.data.favoritesContents;
    } catch (error) {
      //throw error;
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateFavoriteContentsOrder = createAsyncThunk(
  "updateFavoriteContentsOrder",
  async ({ userId, contentId, newItems }, { rejectWithValue }) => {
    try {
      const IdToken = await getIdToken();
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${IdToken}`, //`Bearer ${IdToken}` if access token authorization
        },
      };
      const favoritesData = {
        action: "reorder",
        data: {
          userId: userId,
          favoritesContents: typeof contentId==='string'? [contentId]:[],
          newItems: newItems
        },
      };
      const response = await axios.post(
        `${portalApiBaseUrl}/api/favoritesContents`,
        favoritesData,
        headers
      );
      return response.data.favoritesContents;
    } catch (error) {
      //throw error;
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);