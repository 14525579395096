import React, { useEffect, useState } from "react";
import { Card, Popover } from "@mui/material";
import "./style.scss";
import styles from "./styles";
import Polls from "../../components/Polls";

const PollsPopup = ({
  id,
  setOpen,
  openPop,
  anchorEl,
  onClose,
  polls,
  oAuthToken
}) => {
  const [show, setShow] = useState(false);

  const { classes } = styles();

  useEffect(() => {
    if (polls.question && !polls.user_vote) {
      setShow(true);
    }
  }, [polls]);

  return (
    <>
      <Popover
        id={id}
        open={openPop}
        onClose={onClose}
        anchorEl={anchorEl}
        sx={{ height: "auto", width: "auto" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Card className={classes.pollsCard}>
          {show && <Polls polls={polls} setOpen={setOpen} setShow={setShow} oAuthToken={oAuthToken} />}
        </Card>
      </Popover>
    </>
  );
};

export default PollsPopup;
