import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getIdToken } from "./../../../helper/auth";
import config from "../../../config";

const hostname = window.location.hostname;

const port = window.location.port;

const portalApiBaseUrl = port ? 'http://' + hostname + ":" + port : 'https://' + hostname;

export const getAttachment = createAsyncThunk(
    "/attachment",
    async (url) => {
        try {
            const IdToken = await getIdToken();
            const headers = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${IdToken}`,
                },
                responseType: 'blob',
            };
            const encodedUrl = encodeURIComponent(url);
            const response = await axios.get(
                `${portalApiBaseUrl}/getAttachment/${encodedUrl}`,
                headers
            );
            return response.data;
        } catch (error) {
            console.error("Error fetching attachment:", error);
        }
    }
);
