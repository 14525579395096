import {createSlice} from '@reduxjs/toolkit'
import { getTrainualData } from '../../actions/Trainual/trainual';

const initialState = {
    loading: false,
    error: false,
    trainual:[],
    message: null,
  };

const trainualSlice = createSlice({
    name:'trainual',
    initialState,
    reducers: { },
    extraReducers: (builder) => {
      //Get Notice
      builder.addCase(getTrainualData.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(getTrainualData.fulfilled, (state, action) => {
        state.loading = false;
        state.trainual = action.payload;
        state.error = "";
      });
      builder.addCase(getTrainualData.rejected, (state, action) => {
        state.loading = false;
        state.trainual = [];
        state.error = action.error.message;
      });
    }
})

export default trainualSlice.reducer