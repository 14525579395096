import React from 'react';
import { useTheme } from '@mui/material/styles';
import styles from "./styles";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CustomAccordion = ({ title, children }) => {
  const theme = useTheme();
	const { classes } = styles();
  return (
    <Accordion defaultExpanded className={classes.root} sx={{ boxShadow: 0 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
