import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme) => {
    return {
        trainualRootCard: {
            display: 'flex', height: '140px', boxShadow: 'none', border: 'none'
        },
        trainualCardTitle: {
            color: theme.palette.info.dark,
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Oswald-Regular !important",
            fontSize: "22px !important",
            fontWeight: "700 !important",
            lineHeight: "24px !important",
            letterSpacing: "1px"
        },
        trainualCardImg: {
            borderRadius: '75px', width: 'auto', objectFit: 'cover', height: "60px"
        },
        trainualCardDescription: {
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Montserrat-Regular !important",
            fontSize: "18px !important",
            fontWeight: "300 !important",
            lineHeight: "16px !important",
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            letterSpacing: "1px",
            paddingBottom: "2px"
        }
    }
}
);


export default styles;