import { makeStyles, createStyles } from 'tss-react/mui';
import { useTheme } from "@mui/material/styles";

const styles = makeStyles()((theme) =>{
  return {
    root: {
      borderRadius: "10px",
    },
    form: {
      paddingTop: `${theme.spacing(2)} !important`,
      paddingLeft: `${theme.spacing(3)} !important`,
      paddingRight: `${theme.spacing(3)} !important`,
    },
    formHeading: {
      color: `${theme.palette.info.main} !important`,
      fontFamily: "Montserrat-Regular !important",
      fontSize: "14px !important",
      fontWeight: "600 !important",
      lineHeight: "24px !important",
    },
    formSubHeading: {
      display: "inline-flex",
      alignItems: "center",
      gap: "8px",
      color: theme.palette.common.grey,
      fontFamily: "Montserrat-Regular",
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "16px",
      marginBottom: "15px",
      marginTop: "4px",
    },
    circle: {
      color: theme.palette.common.grey,
      width: "3px !important",
      height: "3px !important",
    },
    radioBtn: {
      color: theme.palette.primary.main,
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: "Montserrat-Regular",
      fontSize: "14px",
      fontWeight: "600",
      lineHeight: "22px",
    },
    radioLebel: {
      color: theme.palette.info.main,
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: "Montserrat-Regular",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "22px",
    },
    radioBtnSelected: {
      "& .Mui-checked": {
        color: theme.palette.primary.main,
      },
    },
    btn: {
      display: "flex !important",
      justifyContent: "flex-end !important",
      alignItems: "center !important",
      flexShrink: "0",
      borderRadius: "31px !important",
      color: theme.palette.common.white,
      textAlign: "center !important",
      fontFamily: "Montserrat-Regular !important",
      fontSize: "16px !important",
      fontWeight: "600 !important",
      lineHeight: "16px !important",
      letterSpacing: "0.1px",
      width: "100%",
      paddingLeft: `${theme.spacing(3)} !important`,
      paddingRight: `${theme.spacing(3)} !important`,
    },
    btnArrow: {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      display: "flex",
      padding: "6px 12px 6px 8px",
      justifyContent: "center",
      alignItems: "center",
      gap: "8px",
      textTransform: "capitalize",
      borderRadius: "20px",
    }
  }
  }
);

export default styles;
