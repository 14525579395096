import React, { useEffect, useState } from "react";
import FavoriteApps from "..";
import FavoriteContents from "../FavoriteContents";
import styles from "../styles";
import { Grid, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import TabPanel from "../../../components/TabPanel/index";
import { useDispatch, useSelector } from "react-redux";
import { getApplicationsAssignedToUser } from "../../../redux/actions/graph";
import { getUserData } from "../../../redux/actions/userData/userData";
import { fetchFavorites } from "../../../redux/actions/graph/favorites";
import { useLocation } from "react-router-dom";

const FavoritePage = (props) => {
  const { classes } = styles();
  const dispatch = useDispatch();
  const location = useLocation();
  const state = location.state;

  const { isDashboard, fetched, handleTabPanelState } = props;
  const [value, setValue] = useState(0);
  const userData = useSelector((state) => state.userData);
  const userId = useSelector((state) => state.userData.userData.id);
  const apps = useSelector((state) => state.apps.apps);
  const favoritesApps = useSelector((state) => state.getFavorites);
  const favoritesContents = useSelector((state) => state.getFavoritesContents);
  const isMobile = useMediaQuery("(max-width:768px)");

  useEffect(() => {
    dispatch(getUserData());
  }, [dispatch]);

  useEffect(() => {
    if (apps?.length === 0 && userId)
      dispatch(getApplicationsAssignedToUser(userId));
  }, [dispatch, userData, apps?.length]);

  useEffect(() => {
    if (userId) {
      dispatch(fetchFavorites(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (handleTabPanelState) {
      handleTabPanelState(value);
    }
  }, [value]);

  useEffect(() => {
    if (state) setValue(state);
  }, [state]);

  const tabs = [
    { label: "Apps", value: 0 },
    { label: "Contents", value: 1 },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const filteredFavoritesContents = favoritesContents?.getFavoritesContents
    ?.favoritesContents
    ? favoritesContents?.getFavoritesContents?.favoritesContents?.filter(
      (content) => content !== null
    )
    : [];

  return (
    <Grid
      container
      className={classes.favContainer}
      style={{ paddingTop: isDashboard ? "0px" : "15px", position: "relative" }}
    >
      {!isDashboard && (
        <Link style={{ zIndex: 1 }} to={-1}>
          <div className={classes.backArrow}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M20 11.1562H7.83L13.42 5.56625L12 4.15625L4 12.1562L12 20.1562L13.41 18.7463L7.83 13.1562H20V11.1562Z"
                fill="#D0D0D2"
              />
            </svg>
            <div className={classes.backTxt}>Back</div>
          </div>
        </Link>
      )}
      <Grid
        item
        md={12}
        style={{
          position: isDashboard ? (isMobile ? "static" : "absolute") : "static",
          top: isDashboard ? "-45px" : "",
          minWidth: "100%",
        }}
      >
        <TabPanel
          tabs={tabs}
          value={value}
          handleChange={handleChange}
          isDashboard={isDashboard}
        />
        {/* Content for each tab */}
        {value === 0 && (
          <FavoriteApps
            userId={userId}
            favoritesApps={favoritesApps}
            isDashboard={isDashboard}
            fetched={fetched}
          />
        )}
        {value === 1 && (
          <FavoriteContents
            userId={userId}
            favoritesContents={filteredFavoritesContents}
            isDashboard={isDashboard}
            fetched={fetched}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default FavoritePage;
