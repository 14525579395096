import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme) => {
    return {
        thumbnailName: {
            fontFamily: "Montserrat-Regular",
            fontSize: "16px",
            fontWeight: "800",
            padding: "0px 0px 20px 60px"
        },
        thumbnailContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '60px',
            height: 'auto',
            border: '1px solid #ddd',
            backgroundColor: '#f5f5f5',
            maxWidth: '100%',
        },
        thumbnailImgBox: {
            maxWidth: "100%", textAlign: "center"
        },
        thumbnailImageStyles: {
            maxWidth: "100%",
            maxHeight: "600px",
            objectFit: "contain",
            display: "block",
            margin: "0 auto"
        }
    }
}
);

export default styles;