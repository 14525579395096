import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "./../../../config";

export const postPolls = createAsyncThunk(
  "poll_vote_api",
  async ({ poll_id, poll_option, oAuthToken}, {rejectWithValue }) => {
    console.log("poll",poll_option)
    try {
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${oAuthToken}`, //`Bearer ${accessToken}` if access token authorization
        },
      };
      const response = await axios.post(
        `${config.drupalapiBaseUrl}/api/poll_vote_api?poll_id=${poll_id}&poll_option=${poll_option}`,
        {},
        headers
      );

      if (response.status === 200) {
        sessionStorage.setItem("refreshDialog", "false");
      }

      return response.data;
    } catch (error) {
      //throw error;
      // return custom error message from backend if present
      if (error.response.status) {
        sessionStorage.setItem("refreshDialog", "true");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
