import {createSlice} from '@reduxjs/toolkit'
import { postPolls } from '../../actions/polls/pollsPost';

const initialState = {
    loading: false,
    error: false,
    pollsPost:[],
    message: null,
    fetched:false
  };

const pollsPostSlice = createSlice({
    name:'pollsPost',
    initialState,
    reducers: { },
    extraReducers: (builder) => {
      //Get site Alerts
      builder.addCase(postPolls.pending, (state) => {
        state.loading = true;
        state.fetched = false;
      });
      builder.addCase(postPolls.fulfilled, (state, action) => {
        state.loading = false;
        state.pollsPost = action.payload;
        state.error = "";
        state.fetched=true
      });
      builder.addCase(postPolls.rejected, (state, action) => {
        state.loading = false;
        state.pollsPost = [];
        state.error = action.error.message;
        state.fetched=false
      });
    }
})

export default pollsPostSlice.reducer
