import {createSlice} from '@reduxjs/toolkit'
import { getPolls } from '../../actions/polls/polls';

const initialState = {
    loading: false,
    error: false,
    polls:[],
    message: null,
  };

const pollsSlice = createSlice({
    name:'polls',
    initialState,
    reducers: { },
    extraReducers: (builder) => {
      //Get site Alerts
      builder.addCase(getPolls.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(getPolls.fulfilled, (state, action) => {
        state.loading = false;
        state.polls = action.payload;
        state.error = "";
      });
      builder.addCase(getPolls.rejected, (state, action) => {
        state.loading = false;
        state.polls = [];
        state.error = action.error.message;
      });
    }
})

export default pollsSlice.reducer
