import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";

function FavoriteIconButton({ isFavorite, onClick, isDashboard, isDetail,detailPagePath }) {
  const [isFilled, setIsFilled] = useState(false);

  useEffect(() => {
    setIsFilled(isFavorite); // Update the isFilled state based on the isFavorite prop
  }, [isFavorite]);

  const handleIconClick = () => {
    const newFilled = !isFilled;
    setIsFilled(newFilled);
    onClick && onClick(newFilled); // Notify parent component of the click event with the new filled state
  };

  return (
    <IconButton
      onClick={handleIconClick}
      disableRipple
      style={{ float: "right" }}
    >
      {isFilled ? (
        <FavoriteIcon
          sx={{
            fontSize: isDashboard ? "2rem" : "1.5rem",
            color: isDashboard || isDetail ? "#0C3752" : "primary",
          }}
          color="primary"
        />
      ) : (
        <FavoriteBorderOutlinedIcon
          sx={{
            fontSize: isDashboard ? "2rem" : "1.5rem",
            color: isDashboard || isDetail ? "#0C3752" : "primary",
          }}
          color="primary"
        />
      )}
    </IconButton>
  );
}

export default FavoriteIconButton;
