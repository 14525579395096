import React from 'react';
import styles from "./styles";
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from '@mui/material';
import Breadcrumb from '../../components/Breadcrumb/index'
import { Link, useLocation } from "react-router-dom";
import Footer from '../../components/Footer';
import Header from "../../components/Header/Index";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch, useSelector } from 'react-redux';
import { setTrainingDetail } from '../../redux/reducers/TrainingDetail/trainingDetailSlice';

export default function TrainingList() {
    const theme = useTheme();
    const { classes } = styles();
    const dispatch = useDispatch();
    const trainingList = useSelector((state) => state.trainingList.trainingListItem);
    const subjectTitle = trainingList && trainingList.subject_title;
    const subjectSummary = trainingList && trainingList.subject_summary;

    const handleTrainingDetail = (listItem, subjectTitle) => {
        dispatch(
            setTrainingDetail({
                trainingDetailItem: { ...listItem, subjectTitle }
            })
        );
    };

    return (
        <>
            <Header trainualPage={true} position={"relative"} top="0px" />
            <Box className={classes.trainualListContainer}>
                <div className={classes.trainualListHeaderBox}>
                    <Breadcrumb subjectTitle={subjectTitle} />
                    <div className={classes.trainualListHeaderText}>{subjectTitle.replace(/&amp;/gi, '&')}</div>
                    <p className={classes.trainualListDescription}>{subjectSummary.replace(/&amp;/gi, '&')}</p>
                </div>
                <Box className={classes.trainualListItemBox}>
                    {trainingList && trainingList?.subject_topic.map((listItem, index) => (
                        <Box key={index} className={classes.trainualListItem}>
                            <Link style={{ textDecoration: 'none' }} to={'/TrainingDetail'} onClick={() => handleTrainingDetail(listItem, subjectTitle)}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: 'space-between',
                                        cursor: "pointer",
                                    }}
                                >
                                    <Typography>{listItem.topic_title.replace(/&amp;/gi, '&')}</Typography>
                                    <ArrowForwardIcon sx={{ color: theme.palette.common.grey }} />
                                </div>
                            </Link>
                        </Box>
                    ))}
                </Box>
            </Box>
            <Footer />
        </>
    );
};