// reducers/graphSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  addFavoriteContents,
  removeFavoriteContents,
  updateFavoriteContentsOrder,
  fetchFavorites,
} from "../../actions/graph/favorites";

const initialState = {
  getFavoritesContents: [],
  favoritesContents: [],
  loading: false,
  error: null,
  fetched: false,
};

const getFavoritesContentsSlice = createSlice({
  name: "favoritesContents",
  initialState,
  extraReducers: (builder) => {
    //For Contents
        //Get Favorites Contents
        builder.addCase(fetchFavorites.pending, (state) => {
          state.loading = true;
          state.fetched = false;
        });
        builder.addCase(fetchFavorites.fulfilled, (state, action) => {
          state.loading = false;
          state.getFavoritesContents = action.payload!==undefined?action.payload[1]:{};
          state.error = "";
          state.fetched = true;
        });
        builder.addCase(fetchFavorites.rejected, (state, action) => {
          state.loading = false;
          state.getFavoritesContents = [];
          state.error = action.error.message;
          state.fetched = false;
        });
        //Add Favorites Apps
        builder.addCase(addFavoriteContents.pending, (state) => {
          state.loading = true;
          state.error = null;
          state.fetched = false;
        });
        builder.addCase(addFavoriteContents.fulfilled, (state, action) => {
          state.loading = false;
          state.favoritesContents.push(action.payload);
          state.error = "";
          state.fetched = true;
        });
        builder.addCase(addFavoriteContents.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
          state.fetched = false;
        });
        //Remove Favorites Apps
        builder.addCase(removeFavoriteContents.pending, (state) => {
          state.loading = true;
          state.error = null;
          state.fetched = false;
        });
        builder.addCase(removeFavoriteContents.fulfilled, (state, action) => {
          state.loading = false;
          state.favoritesContents = state.favoritesContents.filter(
            (fav) => fav.contentId !== action.payload.contentId
          );
          state.error = "";
          state.fetched = false;
        });
        builder.addCase(removeFavoriteContents.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
          state.fetched = false;
        });
        //Update Favorites order
        builder.addCase(updateFavoriteContentsOrder.pending, (state) => {
          state.loading = true;
          state.error = null;
          state.fetched = false;
        });
        builder.addCase(updateFavoriteContentsOrder.fulfilled, (state, action) => {
          state.loading = false;
          state.favoritesContents = action.payload;
          state.error = "";
          state.fetched = true;
        });
        builder.addCase(updateFavoriteContentsOrder.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
          state.fetched = false;
        });
  },
});

export default getFavoritesContentsSlice.reducer;
