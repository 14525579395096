import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme) => {
    return {
        trainualListItem: {
            display: "flex",
            flexDirection: "column",
            backgroundColor: '#fff',
            padding: '10px',
            borderRadius: '8px',
            border: '1px solid #ccc',
            margin: '10px 0',
            '&:hover': {
                backgroundColor: '#f1f9fe',
            },
        },
        trainualListContainer: {
            height: "100%"
        },
        trainualListItemBox: {
            padding: "0px 100px 0px 100px",
            minHeight: "65vh",
            overflowY: "auto"
        },
        trainualListHeaderText: {
            color: '#0C3752',
            fontFamily: 'Oswald-Regular',
            fontSize: '32px',
            fontWeight: '600',
            lineHeight: '38px'
        },
        trainualListHeaderBox: {
            margin: "30px 100px 30px 100px",
        },
        trainualListDescription: {
            marginTop: '24px'
        }
    }
}
);


export default styles;