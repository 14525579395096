import { createContext, useEffect, useState, useRef } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import "./App.scss";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  MsalProvider,
} from "@azure/msal-react";
import { makeStyles } from "tss-react/mui";
import Dashboard from "./views/Dashboard/index.js";
import Login from "./views/Login";
import SearchResult from "./../src/views/SearchResult";
import NewSearchResult from "./../src/views/NewSearchResult";
import DetailPage from "./components/DetailPage/index.js";
import { useDispatch, useSelector } from "react-redux";
import { getFeedback } from "./redux/actions/Feedback/feedback.js";
import { Button } from "@mui/material";
import Feedback from "./views/Feedback/index.js";
import styles from "./styles.js";
import { useIdleTimer } from "react-idle-timer"; // Import IdleTimer
import FavoriteApps from "./views/FavoriteApps/index.js";
import { fetchAndStoreToken } from "./redux/actions/oAuthToken/oAuthToken.js";
import GoogleTagManager from "./components/GoogleTagManager/index.js";
import CustomizedSnackbars from "./components/Snackbar/index.js";
import { getUserData } from "./redux/actions/userData/userData.js";
import ResponsiveDialog from "./components/Dialog/dialogBox.js";
import FavoritePage from "./views/FavoriteApps/FavoritePage/index.js";
import CryptoJS from "crypto-js";
import PollsPopup from "./views/PollsPopup/index.js";
import { getPolls } from "./redux/actions/polls/polls.js";
import TrainingList from "./views/TrainingList/index.js";
import TrainingDetail from "./views/TrainingDetail/index.js";
import Preview from "./components/PreviewPage/index.js";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      flexGrow: 1,
    },
    container: {
      display: "flex",
      flexDirection: "column",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      width: "calc(100% - 100px)",
      height: "100vh",
    },
  };
});

const WrappedView = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const username = activeAccount && activeAccount.username;
  const hashedUsername = CryptoJS.SHA256(username).toString(CryptoJS.enc.Hex);
  const [feedbackAnchorEl, setFeedbackAnchorEl] = useState(null);
  const [searchDialogState, setSearchDialogState] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [pollsAnchorEl, setPollsAnchorEl] = useState(null);
  const [showPollsButton, setShowPollsButton] = useState(false);
  const [openPolls, setOpenPolls] = useState(false);
  const { polls } = useSelector((state) => state.polls);
  const { pollsPost, fetched } = useSelector((state) => state.pollsPost);
  const { feedback } = useSelector((state) => state.feedback);
  const { feedbackPost, loader } = useSelector((state) => state.feedbackPost);
  const idleTimerRef = useRef(null); // Ref for IdleTimer
  const { oAuthToken, error } = useSelector((state) => state.oAuthToken);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openPollSnackbar, setOpenPollSnackbar] = useState(false);
  const [openSessionDialog, setOpenSessionDialog] = useState(false);
  const [openRefreshDialog, setOpenRefreshDialog] = useState(false);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [logoutTimer, setLogoutTimer] = useState(null);
  const dispatch = useDispatch();
  const { classes } = styles();
  const navigate = useNavigate();

  useEffect(() => {
    if (oAuthToken) {
      if (!feedback["#title"])
        dispatch(getFeedback(localStorage.getItem("oAuthToken")));
      setTimeout(() => dispatch(getPolls(oAuthToken)), 30000);
    }
  }, [dispatch, oAuthToken]);

  useEffect(() => {
    if (sessionStorage.getItem("sessionDialog") === "true")
      setOpenSessionDialog(true);
    else setOpenSessionDialog(false);
  }, [oAuthToken]);

  const checkDialogs = () => {
    const refreshDialog = sessionStorage.getItem("refreshDialog");
    setOpenRefreshDialog(refreshDialog === "true");
  };
  useEffect(() => {
    // Check dialogs initially and every time refreshDialog changes
    checkDialogs();

    // Polling interval to check for changes in sessionStorage
    const intervalId = setInterval(() => {
      checkDialogs();
    }, 1000); // Check every second

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, [dispatch]);

  const handleOnIdle = () => {
    setOpenWarningDialog(true);

    // Set up a timer to handle logout if still idle after 5 more minutes
    const timer = setTimeout(() => {
      setOpenWarningDialog(false);
      // Perform logout actions
      localStorage.clear();
      sessionStorage.clear();
      navigate("/");
      setShowLogin(true); // Show login component
      window.location.reload();
    }, 5 * 60 * 1000); // 5 minutes in milliseconds

    setLogoutTimer(timer);
  };

  const handleContinueSession = () => {
    // User chose to continue session
    if (logoutTimer) {
      clearTimeout(logoutTimer);
      setLogoutTimer(null);
    }
  };

  useIdleTimer({
    timeout: 50 * 60 * 1000, // 50 mins
    onIdle: handleOnIdle,
    debounce: 500,
    crossTab: true,
    ref: idleTimerRef,
  });

  const renderContent = () => {
    if (showLogin) {
      return <Login setShowLogin={setShowLogin} />;
    }
    return (
      <Routes>
        {routePath === "/" ? (
          <Route
            path="/"
            element={<Dashboard setSearchDialogState={setSearchDialogState} />}
          />
        ) : routePath === "/SearchResult" ? (
          <Route
            path="/SearchResult"
            element={
              <SearchResult
                searchDialogState={searchDialogState}
                setSearchDialogState={setSearchDialogState}
              />
            }
          />
        ) : routePath === "/dSearchResult" ? (
          <Route path="/dSearchResult" element={<NewSearchResult />} />
        ) : routePath === "/Favorites" ? (
          <Route path="/Favorites" element={<FavoritePage />} />
        ) : routePath === "/TrainingList" ? (
          <Route path="/TrainingList" element={<TrainingList />} />
        ) : routePath.startsWith("/preview/")  ? (
          <Route path="/preview/*" element={<Preview />} />
        ) : routePath === "/TrainingDetail" ? (
          <Route path="/TrainingDetail" element={<TrainingDetail />} />
        ) : (
          <Route path={routePath} element={<DetailPage />} />
        )}
        <Route
          path="/"
          element={<Dashboard setSearchDialogState={setSearchDialogState} />}
        />
        <Route
          path="/SearchResult"
          element={
            <SearchResult
              searchDialogState={searchDialogState}
              setSearchDialogState={setSearchDialogState}
            />
          }
        />
        <Route path="/dSearchResult" element={<NewSearchResult />} />
        <Route path="/Favorites" element={<FavoritePage />} />
        <Route path="/TrainingList" element={<TrainingList />} />
        <Route path="/TrainingDetail" element={<TrainingDetail />} />
        <Route path="/department/:id" element={<DetailPage />} />
        <Route path="/notice_board/:id" element={<DetailPage />} />
        <Route path="/policies_and_procedures/:id" element={<DetailPage />} />
        <Route path="/business_development/:id" element={<DetailPage />} />
        <Route path="/corporate_symposium/:id" element={<DetailPage />} />
        <Route path="/form/:id" element={<DetailPage />} />
        <Route path="/human_resource/:id" element={<DetailPage />} />
        <Route path="/marketing/:id" element={<DetailPage />} />
        <Route path="/newsletter/:id" element={<DetailPage />} />
        <Route path="/product/:id" element={<DetailPage />} />
        <Route path="/strategic_partner_program/:id" element={<DetailPage />} />
        <Route path="/training/:id" element={<DetailPage />} />
        <Route path="/transition/:id" element={<DetailPage />} />
        <Route path="/document/:id" element={<DetailPage />} />
        <Route path="/site_alert/:id" element={<DetailPage />} />
        <Route path="/preview/department/:id" element={<Preview />} />
        <Route path="/preview/notice_board/:id" element={<Preview />} />
        <Route path="/preview/policies_and_procedures/:id" element={<Preview />} />
        <Route path="/preview/business_development/:id" element={<Preview />} />
        <Route path="/preview/corporate_symposium/:id" element={<Preview />} />
        <Route path="/preview/form/:id" element={<Preview />} />
        <Route path="/preview/human_resource/:id" element={<Preview />} />
        <Route path="/preview/marketing/:id" element={<Preview />} />
        <Route path="/preview/newsletter/:id" element={<Preview />} />
        <Route path="/preview/product/:id" element={<Preview />} />
        <Route path="/preview/strategic_partner_program/:id" element={<Preview />} />
        <Route path="/preview/training/:id" element={<Preview />} />
        <Route path="/preview/transition/:id" element={<Preview />} />
        <Route path="/preview/document/:id" element={<Preview />} />
        <Route path="/preview/site_alert/:id" element={<Preview />} />
      </Routes>
    );
  };

  useEffect(() => {
    const refreshDelay = 110 * 60 * 1000; // 110 minutes in milliseconds

    const intervalId = setInterval(() => {
      dispatch(fetchAndStoreToken()); // Dispatch action to refresh token
    }, refreshDelay);

    // Cleanup function to clear the interval when component unmounts or refreshDelay changes
    return () => clearInterval(intervalId);
  }, [dispatch]);

  useEffect(() => {
    if (feedbackPost?.sid) setOpenSnackbar(true);
  }, [feedbackPost?.sid]);

  useEffect(() => {
    if (pollsPost?.status) {
      setOpenPollSnackbar(true);
      setShowPollsButton(false);
    }
  }, [pollsPost]);

  useEffect(() => {
    if (polls?.question && !polls?.user_vote) {
      setShowPollsButton(true);
    }
  }, [polls]);

  const handleFeedbackClick = (event) => {
    setFeedbackAnchorEl(event.currentTarget);
    setOpenFeedback(!openFeedback);
  };

  const handleFeedbackClose = () => {
    setFeedbackAnchorEl(null);
    setOpenFeedback(!openFeedback);
  };

  const handlePollsClick = (event) => {
    setPollsAnchorEl(event.currentTarget);
    setOpenPolls(!openPolls);
  };

  const handlePollsClose = () => {
    setPollsAnchorEl(null);
    setOpenPolls(!openPolls);
  };

  const openFeedbackPop = Boolean(feedbackAnchorEl);
  const feedbackId = openFeedbackPop ? "feedback" : undefined;

  const openPollsPop = Boolean(pollsAnchorEl);
  const pollsId = openPollsPop ? "polls" : undefined;

  const routePath = window.location.pathname;

  return (
    <div className="App">
      <AuthenticatedTemplate>
        {activeAccount ? renderContent() : null}
        <GoogleTagManager hashedUsername={hashedUsername} />
        {/* Feedback button */}
        <div
          className={classes.feedbackButtonContainer}
          onClick={handleFeedbackClick}
        >
          {!openFeedback && !showLogin && (
            <Button
              aria-describedby={feedbackId}
              variant="contained"
              className={classes.feedbackButton}
            >
              <span className={classes.feedbackText}>Feedback</span>
            </Button>
          )}
        </div>
        <div
          className={classes.feedbackButtonContainer}
          style={{ bottom: "248px" }}
          onClick={handlePollsClick}
        >
          {showPollsButton && !openPolls && !showLogin && (
            <Button
              aria-describedby={pollsId}
              variant="contained"
              className={classes.feedbackButton}
            >
              <span className={classes.feedbackText}>Polls</span>
            </Button>
          )}
        </div>
        {feedbackPost?.sid && !loader && (
          <CustomizedSnackbars
            message="Your Feedback has been submitted successfully"
            vertical="bottom"
            horizontal="center"
            open={openSnackbar}
            setOpen={setOpenSnackbar}
          />
        )}
        {openPollSnackbar && fetched && (
          <CustomizedSnackbars
            message="Your response has been submitted successfully"
            vertical="bottom"
            horizontal="center"
            open={openPollSnackbar}
            setOpen={setOpenPollSnackbar}
          />
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login setShowLogin={setShowLogin} />
      </UnauthenticatedTemplate>
      {openFeedback && feedback["#title"] && oAuthToken && !showLogin && (
        <Feedback
          id={feedbackId}
          setOpen={setOpenFeedback}
          openPop={openFeedbackPop}
          anchorEl={feedbackAnchorEl}
          onClose={handleFeedbackClose}
          feedback={feedback}
          oAuthToken={localStorage.getItem("oAuthToken")}
        />
      )}
      {openPolls && oAuthToken && !showLogin && (
        <PollsPopup
          id={pollsId}
          setOpen={setOpenPolls}
          openPop={openPollsPop}
          anchorEl={pollsAnchorEl}
          onClose={handlePollsClose}
          polls={polls}
          oAuthToken={localStorage.getItem("oAuthToken")}
        />
      )}
      {openSessionDialog && !showLogin && (
        <ResponsiveDialog
          setOpen={setOpenSessionDialog}
          open={openSessionDialog}
          title="Session expired"
          description="Click 'Login' to sign in again and resume your activity."
          activityButtonMessage="Login"
        />
      )}
      {openRefreshDialog && !showLogin && (
        <ResponsiveDialog
          setOpen={setOpenRefreshDialog}
          open={openRefreshDialog}
          title="Session expired"
          description="Click 'Login' to sign in again and resume your activity."
          activityButtonMessage="Login"
        />
      )}
      {openWarningDialog && !showLogin && (
        <ResponsiveDialog
          setOpen={setOpenWarningDialog}
          open={openWarningDialog}
          title="Session Expiration Warning"
          description="You have been idle for a while. Your session will expire soon. Click 'OK' to continue your session, or you will be logged out automatically."
          activityButtonMessage="Logout"
          handleContinueSession={handleContinueSession}
        />
      )}
    </div>
  );
};

export const GlobalInfo = createContext();

const App = ({ instance }) => {
  const { classes } = useStyles();
  return (
    <GlobalInfo.Provider>
      <div className={classes.container}>
        <MsalProvider instance={instance}>
          <WrappedView />
        </MsalProvider>
      </div>
    </GlobalInfo.Provider>
  );
};

export default App;
