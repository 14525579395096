import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "./../../../config";

export const getSearch = createAsyncThunk(
  "/api/search",
  async ({ searchQuery, queryString, page, oAuthToken }, { rejectWithValue }) => {
    try {
      const headers = {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Basic ${basicAuthString}`, //`Bearer ${accessToken}` if access token authorization
          Authorization: `Bearer ${oAuthToken}`, //`Bearer ${accessToken}` if access token authorization
        },
      };
      const searchQueryRegex = /(^''$|^""$|^'$|^"$)/g;
      // const searchQueryRegex = /(^''$|^""$|^'$|^"$|^"".{3,}""$)/g;
      const matches = searchQuery && searchQuery.match(searchQueryRegex);

      const url = queryString
        ? `${config.drupalapiBaseUrl}/api/v1/search?srh_key=${matches ? '' : searchQuery}&${queryString}&page=${page}`
        : `${config.drupalapiBaseUrl}/api/v1/search?srh_key=${matches ? '' : searchQuery}&page=${page}`;

      const response = await axios.get(url, headers);

      if (response.status === 200) {
        sessionStorage.setItem("refreshDialog", "false");
      }

      return response.data;
    } catch (error) {
      //throw error;
      // return custom error message from backend if present
      if (error.response.status) {
        sessionStorage.setItem("refreshDialog", "true");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
