import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../../config";

//polls_api
export const getPolls = createAsyncThunk(
  "poll_api",
  async (oAuthToken, { rejectWithValue }) => {
    try {
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${oAuthToken}`,
        },
      };
      const response = await axios.get(`${config.drupalapiBaseUrl}/api/poll_api`, headers);

            
      if (response.status === 200) {
        sessionStorage.setItem("refreshDialog", "false");
      } 

      return response.data.data;
    } catch (error) {
      //throw error;
      // return custom error message from backend if present
      if (error.response.status) {
        sessionStorage.setItem("refreshDialog", "true");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
